<template>
  <div>
    <AppInput
      v-model="model.seoTitle"
      :label="$t('label.seoTitle')"
      class="q-mb-md"
    />
    <AppInput
      v-model="model.seoDescription"
      :label="$t('label.seoDescription')"
      type="textarea"
    />
  </div>
</template>

<script>
import AppInput from 'common/components/AppInput';

export default {
  name: 'SEOOptions',
  components: {
    AppInput,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        seoTitle: undefined,
        seoDescription: undefined,
      }),
    },
  },
  data() {
    return {
      model: {
        ...this.value,
      },
    };
  },
  watch: {
    model: {
      deep: true,
      handler() {
        this.$emit('input', this.model);
      },
    },
  },
};
</script>
