<template>
  <div class="sidebar full-height column">
    <div class="col column q-mb-lg">
      <div class="col column no-wrap">
        <div class="header q-px-lg border-bottom">
          <AppTabs
            v-model="currentTab"
            align="left"
            :breakpoint="250"
          >
            <q-tab
              v-for="tab in tabs"
              :key="tab.name"
              :ripple="false"
              :name="tab.name"
              :label="tab.label"
              class="no-hover q-pa-none q-mr-md"
            />
          </AppTabs>
        </div>
        <q-tab-panels
          v-model="currentTab"
          class="body"
        >
          <q-tab-panel
            name="block"
            class="scroll q-mt-none q-pa-lg q-gutter-y-lg block page-blocks"
          >
            <div
              v-for="(block, index) in blocks"
              :key="block.id"
              :data-id="block.id"
              :class="['flex justify-between items-center draggable-field', { 'q-mt-none': !index }]"
            >
              <AppToggle
                v-model="form.jsonSchema[blocksSource][index].isActive"
                :label="block.label"
              />
              <q-icon
                class="drag-icon cursor-pointer"
                name="fl:grip-dots-vertical"
                size="28px"
              />
            </div>
          </q-tab-panel>
          <q-tab-panel
            name="design"
            class="scroll q-px-lg q-pt-xs q-pb-lg"
          >
            <DesignEmailOptions v-if="isEmailTab"/>
            <DesignOptions
              v-else
              v-model="form.jsonSchema.designOptions"
            />
          </q-tab-panel>
          <q-tab-panel
            name="footer"
            class="q-pa-none column no-wrap relative-position"
          >
            <EmailFooterLinks class="column col"/>
          </q-tab-panel>
          <q-tab-panel
            name="seo"
            class="scroll q-px-lg q-pt-xs q-pb-lg"
          >
            <SEOOptions v-model="form.jsonSchema.seo"/>
          </q-tab-panel>
          <q-tab-panel
            name="smtp"
            class="scroll q-px-lg q-pt-xs q-pb-lg"
          >
            <SmtpOptions/>
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </div>

    <div class="full-width flex">
      <AppButton
        :disabled="isPreviewDisabled"
        icon-left="fl:hide-pass"
        icon-left-color="grey-7"
        icon-left-size="24px"
        class="q-mr-md"
        outline
        @click="showPreview"
      />
      <div class="col">
        <AppButtonLink
          align="between"
          :label="$t('button.save')"
          :to="pageListRoute"
          :flat="false"
          icon-right="fl:done"
          icon-right-color="black"
          icon-right-size="24px"
          color="primary"
          text-color="black"
          full-width
        />
      </div>
    </div>

    <EmailsPreviewDialog
      v-if="showEmailsPreviewDialog"
      @close="showEmailsPreviewDialog = false"
    />
  </div>
</template>

<script>
import AppTabs from 'common/components/AppTabs';
import AppToggle from 'common/components/AppToggle';
import DesignEmailOptions from 'common/components/builder/email/DesignEmailOptions';
import EmailFooterLinks from 'common/components/builder/email/EmailFooterLinks';
import SmtpOptions from 'common/components/builder/email/SmtpOptions';
import AppButton from 'common/components/buttons/AppButton';
import AppButtonLink from 'common/components/buttons/AppButtonLink';
import EmailsPreviewDialog from 'common/components/dialogs/EmailsPreviewDialog';
import getPreviewLink from 'common/helpers/getPreviewLink';
import Sortable from 'sortablejs';
import DesignOptions from 'src/pages/dashboard/pageBuilder/DesignOptions';
import SEOOptions from 'src/pages/dashboard/pageBuilder/SEOOptions';
import pageBuilderMixin from 'src/pages/dashboard/pageBuilder/mixins/pageBuilderMixin';
import { mapState } from 'vuex';

export default {
  name: 'PageBuilderSidebar',
  components: {
    EmailsPreviewDialog,
    EmailFooterLinks,
    SmtpOptions,
    SEOOptions,
    DesignEmailOptions,
    DesignOptions,
    AppButton,
    AppButtonLink,
    AppTabs,
    AppToggle,
  },
  mixins: [pageBuilderMixin],
  data() {
    return {
      sortable: undefined,
      currentTab: 'block',
      showEmailsPreviewDialog: false,
    };
  },
  computed: {
    ...mapState('page', ['previewTab']),
    ...mapState('emailWorkflow', ['emailWorkflow']),
    ...mapState('app', ['account']),
    tabs() {
      const { canManageSMTP } = this.account.capabilities;
      return [
        {
          name: 'block',
          label: this.$t('label.block'),
          isActive: !this.isEmailTab,
        },
        {
          name: 'design',
          label: this.$t('label.design'),
          isActive: true,
        },
        {
          name: 'footer',
          label: this.$t('label.footer'),
          isActive: this.isEmailTab,
        },
        {
          name: 'seo',
          label: this.$t('label.seo'),
          isActive: !this.isEmailTab,
        },
        {
          name: 'smtp',
          label: this.$t('label.smtp'),
          isActive: this.isEmailTab && canManageSMTP,
        },
      ].filter(({ isActive }) => isActive);
    },
    blocks() {
      const pageLabels = {
        [ENUMS.PAGE_BLOCKS.LOGO]: this.$t('label.logo'),
        [ENUMS.PAGE_BLOCKS.PROMO]: this.$t('label.promo'),
        [ENUMS.PAGE_BLOCKS.PARTNERS]: this.$t('label.partnerImg'),
        [ENUMS.PAGE_BLOCKS.BLOCKQUOTE]: this.$t('label.blockquote'),
        [ENUMS.PAGE_BLOCKS.FAQ]: this.$t('label.faq'),
        [ENUMS.PAGE_BLOCKS.ABOUT]: this.$t('label.about'),
        [ENUMS.PAGE_BLOCKS.TEAM]: this.$t('label.team'),
        [ENUMS.PAGE_BLOCKS.EMBED]: this.$t('label.video'),
        [ENUMS.PAGE_BLOCKS.FOOTER]: this.$t('label.footer'),
        [ENUMS.PAGE_BLOCKS.THANK_YOU]: this.$t('label.form'),
        [ENUMS.PAGE_BLOCKS.TIMER]: this.$t('label.timer'),
        [ENUMS.PAGE_BLOCKS.BENEFIT]: this.$t('label.benefit'),
        [ENUMS.PAGE_BLOCKS.LIST]: this.$t('label.list'),
        [ENUMS.PAGE_BLOCKS.SHORT_HORIZONTAL_LIST]: this.$t('label.horizontalList'),
        [ENUMS.PAGE_BLOCKS.COLUMN_LIST]: this.$t('label.columnList'),
        [ENUMS.PAGE_BLOCKS.TEXT_IMAGE]: this.$t('label.textImage'),
      };

      return this.form.jsonSchema[this.blocksSource]
        .map((block) => ({
          ...block,
          label: pageLabels[block.id],
        }))
        .sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
          return 0;
        });
    },
    pageListRoute: () => ({ name: ENUMS.ROUTES.DASHBOARD.PAGES }),
    isEmailTab() {
      return this.previewTab === ENUMS.PREVIEW_PAGE_TABS.EMAIL;
    },
    isPreviewDisabled() {
      return this.isEmailTab && this.emailWorkflow?.emails?.length === 0;
    },
  },
  watch: {
    previewTab() {
      if (this.tabs.every(({ name }) => name !== this.currentTab)) {
        this.currentTab = this.tabs[0].name;
      }
    },
  },
  mounted() {
    this.sortable = new Sortable(document.querySelector('.page-blocks'), {
      handle: '.drag-icon',
      draggable: '.draggable-field',
      onEnd: this.onEnd,
    });
  },
  methods: {
    showPreview() {
      if (this.isEmailTab) {
        this.showEmailsPreviewDialog = true;
      } else {
        const source = this.previewTab === ENUMS.PREVIEW_PAGE_TABS.THANK_YOU ? 'preview/thank-you' : 'preview';
        const url = getPreviewLink(this.$route.params.pageGuid, source);
        window.open(url, '_blank');
      }
    },
    onEnd() {
      const list = this.sortable.toArray();
      this.form.jsonSchema[this.blocksSource] = list.map((block, index) => ({
        ...this.form.jsonSchema[this.blocksSource].find(({ id }) => id === block),
        order: index * 10,
      }));
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  width: 300px;

  .header {
    border: 1px solid $grey-2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .body {
    border: 1px solid $grey-2;
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    ::v-deep .q-panel {
      overflow: unset;
    }
  }

  .block {
    ::v-deep {
      .q-toggle--dense {
        .q-toggle__label {
          padding-left: 16px;
        }
      }
    }
  }

  .design {
    .q-item {
      min-height: unset;
    }
  }

  .drag-icon {
    color: #D5D5D5;
  }
}
</style>
