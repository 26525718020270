<template>
  <div class="border-radius-10 border overflow-hidden column">
    <div class="header q-px-lg border-bottom">
      <AppTabs
        v-model="previewTab"
        align="left"
      >
        <q-tab
          v-for="tab in tabs"
          :key="tab.name"
          :ripple="false"
          :name="tab.name"
          :label="tab.label"
          class="no-hover q-pa-none q-mr-md"
        />
      </AppTabs>
    </div>
    <q-tab-panels
      v-model="previewTab"
      class="col"
    >
      <q-tab-panel
        :name="ENUMS.PREVIEW_PAGE_TABS.PAGE"
        class="q-pa-none preview-tab"
      >
        <Render
          :options="form.jsonSchema"
          :form-guid="form.formGuid"
          is-builder
          @edit="onEdit"
        />
      </q-tab-panel>
      <q-tab-panel
        :name="ENUMS.PREVIEW_PAGE_TABS.THANK_YOU"
        class="q-pa-none preview-tab"
      >
        <Render
          :options="form.jsonSchema"
          :form-guid="form.formGuid"
          is-thank-you
          is-builder
          @edit="onEdit"
        />
      </q-tab-panel>
      <q-tab-panel
        :name="ENUMS.PREVIEW_PAGE_TABS.EMAIL"
        class="q-pa-none preview-tab"
      >
        <EmailChains
          :page="page"
          @create-workflow="addWorkflowToPage"
        />
      </q-tab-panel>
    </q-tab-panels>

    <AppModal
      :is-open="showEditingDialog"
      :title="modalTitle"
      transition-show="slide-left"
      transition-hide="slide-right"
      content-class="content-dialog editing-dialog"
      position="right"
      no-maximized
      show-close-btn
      @close="onClose"
    >
      <template v-if="showEditingDialog">
        <div class="border-bottom">
          <AppTabs
            v-model="modalTab"
            align="left"
          >
            <q-tab
              v-for="tab in modalTabs"
              :key="tab.name"
              :ripple="false"
              :name="tab.name"
              :label="tab.label"
              class="no-hover q-pa-none"
            />
          </AppTabs>
        </div>
        <q-form
          ref="form"
          class="modal-form scroll"
          greedy
        >
          <q-tab-panels v-model="modalTab">
            <q-tab-panel
              class="q-px-xl q-py-lg"
              name="design"
            >
              <div class="form-builder__option form-builder__option_horizontal">
                <AppToggle
                  v-model="editingBlock.useBackgroundColor"
                  :label="$t('label.useBackgroundColor')"
                  left-label
                />
                <AppColorPicker
                  v-if="editingBlock.useBackgroundColor"
                  v-model="editingBlock.backgroundColor"
                  :label="$t('label.backgroundColor')"
                />
                <div v-t="'label.backgroundImage'"/>
                <AppImagePicker
                  :value="editingBlock.backgroundImage"
                  :style="{ height: '150px' }"
                  :default-image-url="$options.DEFAULT_PAGE_IMAGE_PICKER"
                  short-mode
                  @input="onChangeImage('backgroundImage', $event)"
                />
              </div>

              <PopupButtonSettings
                v-if="showFormButtonSettings"
                v-model="editingBlock"
                class="form-builder__option form-builder__option_horizontal"
              >
                <AppColorPicker
                  v-model="editingBlock.popupBackground"
                  :label="$t('label.popupBackground')"
                />
                <AppSliderInput
                  v-model="editingBlock.popupHeaderTextSize"
                  :label="$t('label.popupHeaderTextSize')"
                  :max="64"
                />
                <AppColorPicker
                  v-model="editingBlock.popupHeaderTextColor"
                  :label="$t('label.popupHeaderTextColor')"
                />
                <AppColorPicker
                  v-model="editingBlock.popupHeaderIconColor"
                  :label="$t('label.popupHeaderIconColor')"
                />
              </PopupButtonSettings>

              <div
                v-if="isBlockquote"
                class="form-builder__option form-builder__option_horizontal"
              >
                <AppColorPicker
                  v-model="editingBlock.blockquoteBackgroundColor"
                  :label="$t('label.blockquoteBackgroundColor')"
                />
              </div>
              <div
                v-if="isFooterBlock"
                class="form-builder__option form-builder__option_horizontal"
              >
                <AppColorPicker
                  v-model="editingBlock.linksFontColor"
                  :label="$t('label.linksFontColor')"
                />
                <AppSliderInput
                  v-model="editingBlock.linksFontSize"
                  :label="$t('label.linksFontSize')"
                />
                <div class="flex items-center justify-between">
                  <div v-t="'label.linksPosition'"/>
                  <AppButtonToggle
                    v-model="editingBlock.linksPosition"
                    :options="linksPosition"
                    class="toggle-buttons"
                    toggle-color="btn-toggle-selected"
                    toggle-text-color="black"
                    text-color="black"
                    padding="13px 18px"
                  />
                </div>
              </div>
              <div
                v-if="showFontSettingsBlock"
                class="form-builder__option form-builder__option_horizontal"
              >
                <template v-if="editingBlock.hasOwnProperty('titleFontColor')">
                  <AppColorPicker
                    v-model="editingBlock.titleFontColor"
                    :label="$t('label.titleFontColor')"
                  />
                  <AppSliderInput
                    v-model="editingBlock.titleFontSize"
                    :label="$t('label.titleFontSize')"
                  />
                  <AppToggle
                    v-model="editingBlock.titleFontBold"
                    :label="$t('label.titleFontBold')"
                    left-label
                  />
                </template>
                <template v-if="editingBlock.hasOwnProperty('descriptionFontColor')">
                  <AppColorPicker
                    v-model="editingBlock.descriptionFontColor"
                    :label="$t('label.descriptionFontColor')"
                  />
                  <AppSliderInput
                    v-model="editingBlock.descriptionFontSize"
                    :label="$t('label.descriptionTextFontSize')"
                  />
                </template>
                <template v-if="showSublabelSettingsBlock">
                  <AppColorPicker
                    v-model="editingBlock.sublabelColor"
                    :label="$t('label.subLabelColor')"
                  />
                  <AppSliderInput
                    v-if="editingBlock.hasOwnProperty('sublabelFontSize')"
                    v-model="editingBlock.sublabelFontSize"
                    :label="$t('label.subLabelTextFontSize')"
                  />
                  <AppToggle
                    v-model="editingBlock.sublabelFontBold"
                    :label="$t('label.sublabelFontBold')"
                    left-label
                  />
                </template>
                <AppColorPicker
                  v-if="editingBlock.hasOwnProperty('labelColor')"
                  v-model="editingBlock.labelColor"
                  :label="$t('label.labelColor')"
                />
                <AppSliderInput
                  v-if="editingBlock.hasOwnProperty('labelFontSize')"
                  v-model="editingBlock.labelFontSize"
                  :label="$t('label.labelFontSize')"
                />
              </div>
              <div
                v-if="editingBlock.hasOwnProperty('photoWidth')"
                class="form-builder__option form-builder__option_horizontal"
              >
                <AppInput
                  :value="editingBlock.photoWidth"
                  :label="$t('label.photoWidth')"
                  @input="editingBlock.photoWidth = castValueAsNumber($event)"
                />
              </div>

              <div
                v-if="editingBlock.hasOwnProperty('itemBorderColor')"
                class="form-builder__option form-builder__option_horizontal"
              >
                <AppColorPicker
                  v-model="editingBlock.itemBorderColor"
                  :label="$t('label.itemBorderColor')"
                />
                <AppSliderInput
                  v-model="editingBlock.itemBorderRadius"
                  :label="$t('label.itemBorderRadius')"
                  :min="0"
                  :max="100"
                />
              </div>
              <FAQDesign
                v-if="isFAQBlock"
                :value="editingBlock.itemsStyle"
                class="form-builder__option form-builder__option_horizontal"
                @input="$set(editingBlock, 'itemsStyle', $event)"
              />
              <TeamDesign
                v-if="isTeamBlock"
                v-model="editingBlock"
                class="form-builder__option form-builder__option_horizontal"
              />
              <ThankYouDesign
                v-if="isThankYouBlock"
                v-model="editingBlock"
                class="form-builder__option"
              />
            </q-tab-panel>
            <q-tab-panel
              class="q-px-xl q-py-lg"
              name="content"
            >
              <div
                v-if="showMainContent"
                class="form-builder__option"
              >
                <div class="q-gutter-y-md">
                  <AppInput
                    v-if="editingBlock.hasOwnProperty('title')"
                    v-model="editingBlock.title"
                    :label="$t('label.title')"
                    type="textarea"
                    autogrow
                  />
                  <AppInput
                    v-if="editingBlock.hasOwnProperty('sublabel')"
                    v-model="editingBlock.sublabel"
                    :label="$t('label.subLabel')"
                    type="textarea"
                    autogrow
                  />
                  <AppInput
                    v-if="editingBlock.hasOwnProperty('description')"
                    v-model="editingBlock.description"
                    :label="$t('label.descriptionText')"
                    type="textarea"
                    autogrow
                  />
                  <AppInput
                    v-if="editingBlock.hasOwnProperty('html')"
                    v-model="editingBlock.html"
                    :label="$t('label.embed')"
                    type="textarea"
                    autogrow
                  />
                  <template v-if="isBlockquote">
                    <AppToggle
                      v-model="editingBlock.showAuthor"
                      :label="$t('label.showAuthor')"
                      left-label
                    />
                    <template v-if="editingBlock.showAuthor">
                      <AppInput
                        v-model="editingBlock.fullName"
                        :label="$t('label.fullName')"
                      />
                      <AppInput
                        v-model="editingBlock.authorPosition"
                        :label="$t('label.position')"
                      />
                    </template>
                  </template>
                  <template v-if="showPhotoUrl">
                    <div v-t="'label.image'"/>
                    <AppImagePicker
                      :value="editingBlock.photoUrl"
                      :style="{ height: '150px' }"
                      :default-image-url="$options.DEFAULT_PAGE_IMAGE_PICKER"
                      short-mode
                      @input="onChangeImage('photoUrl', $event)"
                    />
                  </template>
                  <template v-if="showLogotypeImg">
                    <div v-t="'label.logotypeImage'"/>
                    <AppImagePicker
                      :value="editingBlock.logotypeImage"
                      :style="{ height: '150px' }"
                      :default-image-url="$options.DEFAULT_PAGE_IMAGE_PICKER"
                      short-mode
                      @input="onChangeImage('logotypeImage', $event)"
                    />
                  </template>
                  <template v-if="isTimer">
                    <div class="icon-picker-position">
                      <div>{{ $t('label.showIcon') }}</div>
                      <AppIconPicker
                        v-model="editingBlock.timerDateTimeIcon"
                        :disabled="!editingBlock.timerDateTimeIconEnabled"
                      />
                      <AppToggle
                        v-model="editingBlock.timerDateTimeIconEnabled"
                        left-label
                      />
                    </div>
                    <AppInput
                      v-model="timerDateTime"
                      :label="$t('label.timerDate')"
                    >
                      <template #prepend>
                        <q-icon
                          name="event"
                          class="cursor-pointer q-mr-sm"
                        >
                          <q-popup-proxy
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              v-model="timerDateTime"
                              :options="filterDates"
                              mask="YYYY-MM-DD HH:mm"
                              first-day-of-week="1"
                              today-btn
                              minimal
                            >
                              <div class="row items-center justify-end">
                                <AppButton
                                  v-close-popup
                                  label="Close"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>

                      <template #append>
                        <q-icon
                          name="access_time"
                          class="cursor-pointer"
                        >
                          <q-popup-proxy
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-time
                              v-model="timerDateTime"
                              mask="YYYY-MM-DD HH:mm"
                              format24h
                            >
                              <div class="row items-center justify-end">
                                <AppButton
                                  v-close-popup
                                  label="Close"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-time>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </AppInput>
                  </template>
                </div>
              </div>
              <IterableContent
                v-if="isIterableContent"
                v-model="editingBlock.items"
                v-bind="iterableContentLabels"
                :type="editingBlockName"
                :can-use-footer-text="isFooterBlock"
                class="form-builder__option"
              />
            </q-tab-panel>
          </q-tab-panels>
        </q-form>
      </template>
      <template #footer>
        <div class="q-pa-lg flex full-width border-top">
          <AppButton
            v-close-popup
            :label="$t('button.save')"
            class="col"
            color="primary"
            text-color="black"
            full-width
          />
        </div>
      </template>
    </AppModal>
  </div>
</template>

<script>
import AppColorPicker from 'common/components/AppColorPicker';
import AppIconPicker from 'common/components/AppIconPicker';
import AppImagePicker from 'common/components/AppImagePicker';
import AppInput from 'common/components/AppInput';
import AppModal from 'common/components/AppModal';
import AppSliderInput from 'common/components/AppSliderInput';
import AppTabs from 'common/components/AppTabs';
import AppToggle from 'common/components/AppToggle';
import PopupButtonSettings from 'common/components/PopupButtonSettings';
import IterableContent from 'common/components/builder/IterableContent';
import EmailChains from 'common/components/builder/email/EmailChains';
import AppButton from 'common/components/buttons/AppButton';
import AppButtonToggle from 'common/components/buttons/AppButtonToggle';
import Render from 'common/components/render/Render';
import castValueAsNumber from 'common/helpers/castNumberInput';
import { getTimestamp, formatDate } from 'common/helpers/date';
import { DEFAULT_PAGE_IMAGE_PICKER } from 'common/helpers/defaultImageHost';
import handleError from 'common/helpers/handleError';
import { cloneObject, hasProperty, isObjectEquals } from 'common/helpers/objectHelper';
import { ucfirst } from 'common/helpers/stringHelper';
import FAQDesign from 'src/pages/dashboard/pageBuilder/FAQDesign';
import TeamDesign from 'src/pages/dashboard/pageBuilder/TeamDesign';
import ThankYouDesign from 'src/pages/dashboard/pageBuilder/ThankYouDesign';
import pageBuilderMixin from 'src/pages/dashboard/pageBuilder/mixins/pageBuilderMixin';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'PageBuilderPreview',
  DEFAULT_PAGE_IMAGE_PICKER,
  components: {
    EmailChains,
    ThankYouDesign,
    AppButton,
    AppButtonToggle,
    AppColorPicker,
    AppIconPicker,
    AppImagePicker,
    AppInput,
    AppModal,
    AppSliderInput,
    AppTabs,
    AppToggle,
    FAQDesign,
    IterableContent,
    PopupButtonSettings,
    Render,
    TeamDesign,
  },
  mixins: [pageBuilderMixin],
  data() {
    return {
      showEditingDialog: false,
      editingBlockName: undefined,
      editingBlock: undefined,
      editingSourceName: undefined,
      modalTab: 'design',
      timerDateTime: undefined,
      linksPosition: [
        {
          value: 'justify-start',
          label: this.$t('button.left'),
        },
        {
          value: 'justify-center',
          label: this.$t('button.center'),
        },
        {
          value: 'justify-end',
          label: this.$t('button.right'),
        },
      ],
      modalTabs: [
        {
          name: 'design',
          label: this.$t('label.design'),
        },
        {
          name: 'content',
          label: this.$t('label.content'),
        },
      ],
    };
  },
  computed: {
    ...mapState('app', ['account']),
    tabs() {
      return [
        {
          name: ENUMS.PREVIEW_PAGE_TABS.PAGE,
          label: this.$t('label.page'),
          isActive: true,
        },
        {
          name: ENUMS.PREVIEW_PAGE_TABS.THANK_YOU,
          label: this.$t('label.thankYou'),
          isActive: true,
        },
        {
          name: ENUMS.PREVIEW_PAGE_TABS.EMAIL,
          label: this.$t('label.email'),
          isActive: this.account.capabilities.canManageEmailWorkflow,
        },
      ].filter((tab) => tab.isActive);
    },
    modalTitle() {
      return ucfirst(this.editingBlockName);
    },
    iterableContentLabels() {
      switch (this.editingBlockName) {
        case ENUMS.PAGE_BLOCKS.FOOTER:
          return {
            'add-item-label': 'button.addLink',
            'item-label': 'text.link',
            title: 'label.links',
          };
        default:
          return {};
      }
    },
    isIterableContent() {
      return [
        ENUMS.PAGE_BLOCKS.FAQ,
        ENUMS.PAGE_BLOCKS.TEAM,
        ENUMS.PAGE_BLOCKS.PARTNERS,
        ENUMS.PAGE_BLOCKS.FOOTER,
        ENUMS.PAGE_BLOCKS.BENEFIT,
        ENUMS.PAGE_BLOCKS.LIST,
        ENUMS.PAGE_BLOCKS.SHORT_HORIZONTAL_LIST,
        ENUMS.PAGE_BLOCKS.COLUMN_LIST,
      ].includes(this.editingBlockName);
    },
    isBlockquote() {
      return this.editingBlockName === ENUMS.PAGE_BLOCKS.BLOCKQUOTE;
    },
    isFAQBlock() {
      return this.editingBlockName === ENUMS.PAGE_BLOCKS.FAQ;
    },
    isTeamBlock() {
      return this.editingBlockName === ENUMS.PAGE_BLOCKS.TEAM;
    },
    isFooterBlock() {
      return this.editingBlockName === ENUMS.PAGE_BLOCKS.FOOTER;
    },
    isThankYouBlock() {
      return this.editingBlockName === ENUMS.PAGE_BLOCKS.THANK_YOU;
    },
    isTimer() {
      return this.editingBlockName === ENUMS.PAGE_BLOCKS.TIMER;
    },
    showFormButtonSettings() {
      return [
        ENUMS.PAGE_BLOCKS.PROMO,
        ENUMS.PAGE_BLOCKS.ABOUT,
        ENUMS.PAGE_BLOCKS.COLUMN_LIST,
      ].includes(this.editingBlockName);
    },
    showFontSettingsBlock() {
      if (!this.editingBlock) {
        return false;
      }
      return Boolean(this.editingBlock.descriptionFontSize
        || this.editingBlock.titleFontColor
        || this.editingBlock.titleFontSize
        || this.editingBlock.sublabelColor
        || this.editingBlock.sublabelFontSize
        || this.editingBlock.labelColor
        || this.editingBlock.labelFontSize);
    },
    showSublabelSettingsBlock() {
      if (this.isTimer && this.editingBlock.showTimerLabels) {
        return true;
      }
      return hasProperty(this.editingBlock, 'sublabelColor');
    },
    showPhotoUrl() {
      return hasProperty(this.editingBlock, 'photoUrl')
        || (this.isBlockquote && this.editingBlock.showAuthor);
    },
    showLogotypeImg() {
      return hasProperty(this.editingBlock, 'logotypeImage');
    },
    showMainContent() {
      return hasProperty(this.editingBlock, 'title')
          || hasProperty(this.editingBlock, 'sublabel')
          || hasProperty(this.editingBlock, 'description')
          || hasProperty(this.editingBlock, 'html')
          || this.isBlockquote
          || this.showPhotoUrl
          || this.showLogotypeImg
          || this.isTimer;
    },
    previewTab: {
      get() {
        return this.$store.state.page.previewTab;
      },
      set(value) {
        this.setPreviewTab(value);
      },
    },
  },
  watch: {
    editingBlock: {
      deep: true,
      handler() {
        if (!this.editingBlock) {
          return;
        }

        const block = cloneObject(this.editingBlock);
        const oldBlock = this.form.jsonSchema[this.editingSourceName].find(({ id }) => id === this.editingBlockName);
        this.timerDateTime = formatDate(this.editingBlock.timerDateTime * 1000, 'YYYY-MM-DD HH:mm');

        if (isObjectEquals(oldBlock, block)) {
          return;
        }

        const form = cloneObject(this.form);
        const blockIndex = form.jsonSchema[this.editingSourceName].findIndex(({ id }) => id === this.editingBlockName);
        form.jsonSchema[this.editingSourceName][blockIndex] = block;
        this.form = { ...form };
      },
    },
    timerDateTime() {
      if (!this.editingBlock || !this.isTimer) {
        return;
      }
      this.editingBlock.timerDateTime = getTimestamp(this.timerDateTime);
    },
  },
  methods: {
    ...mapMutations('page', ['setPreviewTab', 'addWorkflowToPage']),
    castValueAsNumber,
    onShow({ name, source }) {
      this.editingBlockName = name;
      this.editingSourceName = source;
      this.editingBlock = { ...this.form?.jsonSchema[this.editingSourceName].find(({ id }) => id === name) };
    },
    onEdit(options) {
      this.onShow(options);
      this.showEditingDialog = true;
    },
    onClose() {
      this.showEditingDialog = false;
      this.editingBlockName = undefined;
      this.editingBlock = undefined;
      this.timerDateTime = undefined;
      this.modalTab = 'design';
    },
    async onChangeImage(field, fileData) {
      if (!fileData) {
        this.$set(this.editingBlock, field, '');
        return;
      }
      try {
        const { data } = await this.$axios.post('files', { fileData });
        this.$set(this.editingBlock, field, data.data.url);
      } catch (error) {
        handleError(error);
      }
    },
    filterDates(date) {
      return date >= formatDate(Date.now(), 'YYYY/MM/DD');
    },
  },
};
</script>

<style lang="scss">
.editing-dialog {
  .q-tab__label {
    padding: 5px 0;
  }

  .q-panel {
    overflow: unset;
  }
}
</style>
