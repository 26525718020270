import { cloneObject, isObjectEquals } from 'common/helpers/objectHelper';
import { debounce } from 'quasar';
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      form: undefined,
    };
  },
  computed: {
    ...mapState('page', ['page']),
    blocksSource() {
      return this.$store.state.page.previewTab === ENUMS.PREVIEW_PAGE_TABS.THANK_YOU ? 'thankYouBlocks' : 'blocks';
    },
  },
  watch: {
    page: {
      deep: true,
      immediate: true,
      handler() {
        this.form = cloneObject(this.page);
      },
    },
    form: {
      deep: true,
      handler: debounce(function () {
        if (isObjectEquals(this.form, this.page)) {
          return;
        }
        this.updatePage(this.form);
      }, 500),
    },
  },
  methods: {
    ...mapActions('page', ['updatePage']),
  },
};
