<template>
  <div>
    <div class="q-pb-lg q-mb-lg border-bottom">
      <AppColorPicker
        v-model="model.activeColor"
        :label="$t('label.activeColor')"
      />
      <AppColorPicker
        v-model="model.pageBackground"
        :label="$t('label.pageBackground')"
      />
    </div>

    <div class="q-pb-lg q-mb-lg border-bottom">
      <div
        v-t="'label.favicon'"
        class="q-mb-sm"
      />
      <AppImagePicker
        :value="model.favicon"
        @input="onChangeImage('favicon', $event)"
      />
    </div>

    <q-item
      v-ripple="false"
      tag="label"
      :class="['q-pa-none no-hover', { 'q-mb-md': model.useBackgroundImage }]"
    >
      <q-item-section>
        <q-item-label>{{ $t('label.useBackgroundImage') }}</q-item-label>
      </q-item-section>
      <q-item-section avatar>
        <AppToggle v-model="model.useBackgroundImage"/>
      </q-item-section>
    </q-item>

    <template v-if="model.useBackgroundImage">
      <div
        v-t="'label.backgroundImage'"
        class="q-mb-sm"
      />
      <AppImagePicker
        :value="model.backgroundImageUrl"
        @input="onChangeImage('backgroundImageUrl', $event)"
      />
    </template>
  </div>
</template>

<script>
import AppColorPicker from 'common/components/AppColorPicker';
import AppImagePicker from 'common/components/AppImagePicker';
import AppToggle from 'common/components/AppToggle';
import { cloneObject, isObjectEquals } from 'common/helpers/objectHelper';
import designMixin from 'common/mixins/designMixin';
import { mapState } from 'vuex';

export default {
  name: 'DesignOptions',
  components: {
    AppColorPicker,
    AppImagePicker,
    AppToggle,
  },
  mixins: [designMixin],
  props: {
    value: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    ...mapState('page', ['previewTab']),
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (isObjectEquals(this.value, this.model)) {
          return;
        }
        this.model = cloneObject(this.value);
      },
    },
    model: {
      deep: true,
      handler() {
        this.$emit('input', this.model);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.grid.app-input {
  display: grid;
  grid-template-columns: 1fr 60px;
}
</style>
