<template>
  <MainLayout v-if="page">
    <div class="page-builder q-pa-lg column window-height">
      <PageBuilderHeader class="q-mb-lg"/>
      <div class="flex q-gutter-x-lg col">
        <PageBuilderPreview class="col"/>
        <PageBuilderSidebar/>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import handleError from 'common/helpers/handleError';
import MainLayout from 'common/layouts/MainLayout';
import PageBuilderHeader from 'src/pages/dashboard/pageBuilder/PageBuilderHeader';
import PageBuilderPreview from 'src/pages/dashboard/pageBuilder/PageBuilderPreview';
import PageBuilderSidebar from 'src/pages/dashboard/pageBuilder/PageBuilderSidebar';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'PageBuilderPage',
  components: {
    PageBuilderPreview,
    PageBuilderSidebar,
    PageBuilderHeader,
    MainLayout,
  },
  props: {
    pageGuid: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('page', ['page']),
  },
  created() {
    this.fetch();
  },
  beforeDestroy() {
    this.clear();
  },
  methods: {
    ...mapActions('page', ['fetchPage']),
    ...mapActions('dashboard', ['fetchForms']),
    ...mapMutations('page', ['clear']),
    async fetch() {
      try {
        this.$q.loading.show();
        await Promise.all([
          this.fetchPage(this.pageGuid),
          this.fetchForms(),
        ]);
      } catch (error) {
        handleError(error);
      } finally {
        this.$q.loading.hide();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.page-builder {
  min-height: 600px;
}
</style>
