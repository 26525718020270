<template>
  <div>
    <AppColorPicker
      v-if="form.itemsFontColor"
      v-model="form.itemsFontColor"
      :label="$t('label.itemsFontColor')"
    />
    <AppSliderInput
      v-if="form.itemsFontSize"
      v-model="form.itemsFontSize"
      :label="$t('label.itemsFontSize')"
    />
    <AppColorPicker
      v-if="form.itemsIconColor"
      v-model="form.itemsIconColor"
      :label="$t('label.itemsIconColor')"
    />
    <AppToggle
      v-model="form.itemsBorder"
      :label="$t('label.itemsBorder')"
      left-label
    />
    <AppColorPicker
      v-if="form.itemsBorder"
      v-model="form.itemsBorderColor"
      :label="$t('label.itemsBorderColor')"
    />
  </div>
</template>

<script>
import AppColorPicker from 'common/components/AppColorPicker';
import AppSliderInput from 'common/components/AppSliderInput';
import AppToggle from 'common/components/AppToggle';
import { isObjectEquals } from 'common/helpers/objectHelper';

export default {
  name: 'FAQDesign',
  components: {
    AppColorPicker,
    AppSliderInput,
    AppToggle,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: undefined,
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        this.form = { ...this.value };
      },
    },
    form: {
      deep: true,
      handler() {
        if (isObjectEquals(this.form, this.value)) {
          return;
        }

        this.$emit('input', this.form);
      },
    },
  },
};
</script>
