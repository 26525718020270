<template>
  <div>
    <div class="form-builder__option img-selector">
      <div
        v-for="type in widgetTypes"
        :key="type"
        :class="['option', { active: isWidgetType(type) }]"
        @click="model.thankYouPageWidgetsType = type"
      >
        <q-img
          :src="require(`assets/widget_${type}.svg`)"
          class="option-img"
          width="64px"
          height="79px"
        />
        {{ $t(`label.${type}`) }}
      </div>
    </div>
    <div class="form-builder__option form-builder__option_horizontal">
      <AppColorPicker
        v-model="model.thankYouPageWidgetsFirstAreaBackgroundColor"
        :label="$t('label.thankYouPageWidgetsFirstAreaBackgroundColor')"
      />
      <AppColorPicker
        v-model="model.thankYouPageWidgetsSecondAreaBackgroundColor"
        :label="$t('label.thankYouPageWidgetsSecondAreaBackgroundColor')"
      />
      <AppSliderInput
        v-model="model.thankYouPageWidgetsBorderRadius"
        :label="$t('label.thankYouPageWidgetsBorderRadius')"
        :min="0"
        :max="90"
        input-container-class="grid-3"
      />
    </div>
    <div class="form-builder__option form-builder__option_horizontal">
      <AppToggle
        v-model="model.thankYouPageWidgetsHead"
        :label="$t('title.headText')"
        left-label
      />
      <AppToggle
        v-model="model.thankYouPageWidgetsLinkToEvent"
        :label="$t('title.linkToEvent')"
        left-label
      />
      <AppToggle
        v-model="model.thankYouPageWidgetsJoinButton"
        :label="$t('title.joinButton')"
        left-label
      />
      <AppToggle
        v-model="model.thankYouPageWidgetsAddToCalendar"
        :label="$t('title.addToCalendarButton')"
        left-label
      />
      <AppToggle
        v-model="model.thankYouPageWidgetsReverseTimer"
        :label="$t('title.reverseTimer')"
        left-label
      />
      <AppToggle
        v-model="model.thankYouPageWidgetsEventDate"
        :label="$t('title.eventDate')"
        left-label
      />
      <AppToggle
        v-model="model.thankYouPageWidgetsPhoneFieldEnabled"
        :label="$t('title.reminderMessages')"
        left-label
      />
    </div>
  </div>
</template>

<script>
import AppColorPicker from 'common/components/AppColorPicker';
import AppSliderInput from 'common/components/AppSliderInput';
import AppToggle from 'common/components/AppToggle';

export default {
  name: 'ThankYouDesign',
  components: {
    AppColorPicker,
    AppSliderInput,
    AppToggle,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: undefined,
      widgetTypes: Object.values(ENUMS.WIDGET_TYPES),
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        this.model = this.value;
      },
    },
    model: {
      deep: true,
      handler() {
        this.$emit('input', this.model);
      },
    },
  },
  methods: {
    isWidgetType(type) {
      return this.model.thankYouPageWidgetsType === type;
    },
  },
};
</script>

<style scoped>

</style>
