<template>
  <div class="header border-radius-10 border">
    <div class="q-px-lg items-center border-bottom q-ml-none">
      <div
        v-t="'label.title'"
        class="text-16-400"
      />
      <AppInput
        v-model="form.title"
        :rules="[rules.REQUIRED]"
        class="col"
        bottom-slots
      >
        <template #error/>
      </AppInput>
      <div
        v-t="'label.form'"
        class="text-16-400"
      />
      <AppSelect
        v-model="form.formGuid"
        :options="forms"
        :rules="[rules.REQUIRED]"
        option-label="title"
        option-value="guid"
        map-options
        emit-value
        bottom-slots
      >
        <template #error/>
      </AppSelect>
      <div class="flex justify-between">
        <AppButton
          :label="$t(form.published ? 'button.unpublish' : 'button.publish')"
          :color="form.published ? 'grey-2' : 'primary'"
          :icon-right="form.published ? 'fl:stop_fill' : 'fl:group_share'"
          icon-right-color="black"
          icon-right-size="24px"
          label-class="q-pr-md"
          padding="8px 22px"
          text-color="black"
          @click="onTogglePublish"
        />
        <AppButtonLink
          icon-left="close"
          icon-left-size="25px"
          icon-left-color="grey-7"
          padding="14px 0"
          :to="pageListRoute"
        />
      </div>
    </div>
    <div class="q-px-lg items-center justify-between">
      <div
        v-t="'label.contentLink'"
        class="text-16-400"
      />
      <AppInput
        v-model="form.contentLinks.digistore"
        :rules="[rules.REQUIRED]"
        class="col"
        bottom-slots
      >
        <template #error/>
        <template #append>
          <q-icon
            name="fl:question"
            class="cursor-pointer"
            color="grey-1"
            @click="showContentLinkDialog = true"
          />
        </template>
      </AppInput>
      <div
        v-t="'label.digistoreProductId'"
        class="text-16-400"
      />
      <AppInput
        v-model="form.digistoreProductId"
        :rules="[rules.REQUIRED, rules.NUMBER]"
        bottom-slots
      >
        <template #error/>
      </AppInput>
      <div class="flex items-center justify-between">
        <div
          v-t="'label.userCommission'"
          class="text-16-400 q-mr-md"
        />
        <AppInput
          v-model="form.commission"
          :rules="[
            rules.REQUIRED,
            (value) => rules.BETWEEN(value, $t('label.userCommission'), 1, 100),
          ]"
          class="q-mr-md commission"
          bottom-slots
        >
          <template #error/>
        </AppInput>
        <div class="text-16-400 text-grey-7">%</div>
      </div>
    </div>

    <AppModal
      v-if="showContentLinkDialog"
      :title="$t('title.help')"
      content-class="content-link-dialog column no-wrap"
      modal-body-class="col scroll"
      parent-class="bg-transparent shadow-0 parent-content-link-dialog"
      is-open
      no-maximized
      show-close-btn
      @close="showContentLinkDialog = false"
    >
      <div class="q-pa-lg">
        <AppInput
          class="custom-link"
          :value="page.contentLinks.custom"
          :label="$t('label.enterContentLink')"
          readonly
        >
          <template #append>
            <div
              v-if="isCopied"
              class="text-14-400"
            >
              {{ $t('text.copied') }}
            </div>
            <q-icon
              v-else
              name="fl:duplicate"
              color="black"
              class="cursor-pointer"
              @click="copyCode(page.contentLinks.custom)"
            />
          </template>
        </AppInput>
        <div class="q-py-md">
          <q-img src="~/assets/content_link_desc.png"/>
        </div>
        <div v-html="$t('text.contentLinkDescription')"/>
      </div>
    </AppModal>
  </div>
</template>

<script>
import AppInput from 'common/components/AppInput';
import AppModal from 'common/components/AppModal';
import AppSelect from 'common/components/AppSelect';
import AppButton from 'common/components/buttons/AppButton';
import AppButtonLink from 'common/components/buttons/AppButtonLink';
import handleError from 'common/helpers/handleError';
import rules from 'common/helpers/rules';
import { copyCodeMixin } from 'common/mixins';
import pageBuilderMixin from 'src/pages/dashboard/pageBuilder/mixins/pageBuilderMixin';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'PageBuilderHeader',
  components: {
    AppButton,
    AppButtonLink,
    AppInput,
    AppSelect,
    AppModal,
  },
  mixins: [pageBuilderMixin, copyCodeMixin],
  data() {
    return {
      showContentLinkDialog: false,
    };
  },
  computed: {
    ...mapState('page', ['page']),
    ...mapState('dashboard', ['forms']),
    pageListRoute: () => ({ name: ENUMS.ROUTES.DASHBOARD.PAGES }),
    rules: () => rules,
  },
  methods: {
    ...mapActions('dashboard', ['setPagePublicity']),
    async onTogglePublish() {
      try {
        this.$q.loading.show();
        await this.setPagePublicity({
          guid: this.page.guid,
          publish: !this.page.published,
        });
      } catch (error) {
        handleError(error);
      } finally {
        this.$q.loading.hide();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  & > div {
    height: 70px;
    display: grid;
    grid-template-columns: 85px 8fr 160px 200px 231px;
    gap: 15px;
  }
  .app-input {
    &.commission {
      width: 60px;
    }
  }
}

.custom-link {
  ::v-deep {
    .q-field--outlined .q-field__control:before {
      border: 2px solid $primary;
    }
  }
}
</style>

<style lang="scss">
.parent-content-link-dialog {
  width: 610px;
  max-width: 610px !important;

  .content-link-dialog {
    max-height: calc(100vh - 96px) !important;
  }
}
</style>
