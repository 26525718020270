<template>
  <div>
    <AppColorPicker
      v-model="options.labelColor"
      :label="$t('label.itemTitleFontColor')"
    />
    <AppSliderInput
      v-model="options.labelFontSize"
      :label="$t('label.itemTitleFontSize')"
      :min="0"
      :max="90"
    />
    <AppColorPicker
      v-model="options.sublabelColor"
      :label="$t('label.itemDescriptionFontColor')"
    />
    <AppSliderInput
      v-model="options.sublabelFontSize"
      :label="$t('label.itemDescriptionFontSize')"
    />
  </div>
</template>

<script>
import AppColorPicker from 'common/components/AppColorPicker';
import AppSliderInput from 'common/components/AppSliderInput';

export default {
  name: 'TeamDesign',
  components: {
    AppSliderInput,
    AppColorPicker,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: undefined,
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        this.options = this.value;
      },
    },
  },
};
</script>
